<template>
    <v-container class="nav">
        <!-- in ios add height="90px" -->
        <v-bottom-navigation
            grow
            app
            padless
            :height="$pad? '90px': '60px'"
            v-model="route"
            background-color="white"
        >
            <template v-for="(item, index) in items">
                <!-- in ios add  style="margin-bottom: 10% !important;" -->
                <v-btn
                    :style="$pad? 'margin-bottom: 10% !important;': ''"
                    :key="index"
                    v-if="check_role(item)"
                    icon
                    :value="item.to"
                    color="primary"
                    v-bind="item"
                    :class="`${item.class || ''}`"
                >
                    <span>{{ $tr(item.title) }}</span>
                    <v-icon :size="item.icon_size">{{ item.icon }}</v-icon>
                </v-btn>
            </template>
        </v-bottom-navigation>
    </v-container>
</template>

<script>
import { get } from "vuex-pathify";
export default {
    name: "BottomNavigatoinRouter",
    methods: {
        check_role(item) {
            if (item.roles) {
                if (item.roles?.allow) {
                    return item.roles.allow.some(
                        (role) => this.user?.role == role
                    );
                } else if (item.roles?.reject) {
                    return !item.roles.reject.some(
                        (role) => this.user?.role == role
                    );
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },
    },
    created() {
        this.route = this.$route.path;
    },
    data() {
        return {
            route: null,
        };
    },
    computed: {
        ...get("app", ["items"]),
        ...get("user", ["user"]),
    },
};
</script>

<style>
/* .v-item-group.v-bottom-navigation .v-btn .v-btn__content{
  margin-bottom: 18% !important;
} */
</style>